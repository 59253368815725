.App {
	position: relative;
	font-family: sans-serif;
	text-align: center;
	width: 100vw;
	min-height: 100vh;
	background-image: url("localhost:3000/doctor-png.jpg");
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	z-index: 1;
}

/*.App::before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.2;
	z-index: -1;
}*/

.wizard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	padding: 0;
	z-index: 2;
	pointer-events: all;
}

* {
	margin: 0;
	padding: 0;
  }
  
  body {
	background: #637d63;
  }
    
  .form {
	max-width: 330px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	background: white;
	padding: 20px;
	margin-top: 30px;
  }
  
  .form .form__custom-button {
	margin-top: 50px;
  }
  
